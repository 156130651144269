import { useState } from "react";
import { Config } from "../../../../config";

export const useGetUser = () => {
  const [loading, setLoading] = useState(false);
  const API_URL = Config.REACT_APP_API_TICKETDOOR;

  const getUser = async (userId: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("ticketdoorToken")}`,
        },
      });

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  return { getUser, loading };
};