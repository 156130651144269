import { useState } from "react";
import { Config } from "../../../../config";
import { UserPermissions } from "../../../../hooks/useUserPermissions";

export const useUpdatePermisssions = () => {
  const [loading, setLoading] = useState(false);  
  const API_URL = Config.REACT_APP_API_TICKETDOOR;
  
  const updatePermissions = async (userId: string, permissions?: UserPermissions) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_URL}/users/${userId}/permissions`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("ticketdoorToken")}`,
        },
        body: JSON.stringify(permissions),
      });
      const data = await res.json();
      console.log("data", data);      
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return { updatePermissions, loading };
};