import create from "zustand";
import { User } from "../types/users.interface";

interface State {
    usersList: User[];
    setUsersList: (usersList?: User[]) => void;
}

const useUserStore = create<State>((set) => ({
    usersList: [],
    setUsersList: (usersList) => set({ usersList }),
}));

export default useUserStore;