import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { User } from '../types/users.interface';

// Definición de tipos para los datos que recibes del API
interface Activity {
  tag: string;
  name: string;
  price_from: number;
  is_active: boolean;
  is_bundle: boolean;
  discount: number;
  purchases_providers: {
    priority: number;
    provider: string;
  }[];
  id: string;
}

interface Venue {
  tags: string[];
  name: string;
  address: string;
  postal_code: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
  city: string;
  region: string;
  country: string;
  id: string;
  activities: Activity[];
}

// Definición del estado para los checkboxes
interface CheckedState {
  [cityName: string]: {
    allChecked: boolean;
    venues: { [venueId: string]: boolean };
  };
}

interface VenueActivitiesCheckboxesProps {
  venues: Venue[];
  setSelectedVenueIds: Dispatch<SetStateAction<string[]>>;
  setDataUser: Dispatch<SetStateAction<any>>;
  userVenuesPermissions?: string[];
  setUserVenuesPermissions?: Dispatch<SetStateAction<string[]>>;
}

export default function VenueActivitiesCheckboxes({ venues, setSelectedVenueIds, setDataUser, userVenuesPermissions, setUserVenuesPermissions }: VenueActivitiesCheckboxesProps) {
  
  useEffect(() => {
    setChecked((prevChecked) => {
      if (userVenuesPermissions) {
        return {
          ...prevChecked,
          ...Object.keys(prevChecked).reduce<CheckedState>((acc, cityName) => {
            acc[cityName] = {
              allChecked: Object.keys(prevChecked[cityName].venues).every(venueId => userVenuesPermissions.includes(venueId)),
              venues: Object.keys(prevChecked[cityName].venues).reduce<{ [venueId: string]: boolean }>((acc, venueId) => {
                acc[venueId] = userVenuesPermissions.includes(venueId);
                return acc;
              }, {})
            };
            return acc;
          }, {})
        };
      }
      return prevChecked;
    });
  }, [setUserVenuesPermissions, userVenuesPermissions]);
  
  // Agrupar los venues por ciudad
  const cities = useMemo(() => {
    const cityMap: { [cityName: string]: Venue[] } = {};

    venues.forEach(venue => {
      const cityName = venue.city;
      if (!cityMap[cityName]) {
        cityMap[cityName] = [];
      }
      cityMap[cityName].push(venue);
    });

    return Object.entries(cityMap).map(([name, venues]) => ({ name, venues }));
  }, [venues]);

  const [checked, setChecked] = useState<CheckedState>(() => {
    const initialChecked: CheckedState = {
      // Inicializar los venues ya seleccionados por el usuario (si los hay)
      ...cities.reduce<CheckedState>((acc, city) => {
        acc[city.name] = {
          allChecked: false,
          venues: city.venues.reduce<{ [venueId: string]: boolean }>((acc, venue) => {
            acc[venue.id] = userVenuesPermissions?.find(id => id === venue.id) ? true : false;
            return acc;
          }, {})
        };
        return acc;
      }, {})
    };
    cities.forEach(city => {
      initialChecked[city.name] = {
        allChecked: false,
        venues: city.venues.reduce<{ [venueId: string]: boolean }>((acc, venue) => {
          acc[venue.id] = userVenuesPermissions?.find(id => id === venue.id) ? true : false;
          return acc;
        }, {})
      };
    });
    return initialChecked;
  });

  const handleCityChange = (cityName: string) => {
    const newChecked = { ...checked };
    const allChecked = !checked[cityName].allChecked;
    newChecked[cityName].allChecked = allChecked;

    // Obtener los IDs de los venues en esta ciudad
    const venueIdsInCity = Object.keys(newChecked[cityName].venues);

    // Marcar o desmarcar todos los venues de esa ciudad
    venueIdsInCity.forEach((venueId) => {
      newChecked[cityName].venues[venueId] = allChecked;
    });

    // Actualizar el estado de selectedVenueIds
    setSelectedVenueIds((prevSelectedVenueIds) => {
      if (allChecked) {
        // Añadir todos los IDs de los venues de esta ciudad
        return Array.from(new Set([...prevSelectedVenueIds, ...venueIdsInCity]));
      } else {
        // Eliminar todos los IDs de los venues de esta ciudad
        return prevSelectedVenueIds.filter(id => !venueIdsInCity.includes(id));
      }
    });

    setDataUser((prevDataUser: any) => {
      if (prevDataUser) {
        const newPermissions = prevDataUser.user_permissions;
        const venuesIds = Object.keys(newChecked).reduce<string[]>((acc, cityName) => {
          const venuesInCity = Object.keys(newChecked[cityName].venues).filter(venueId => newChecked[cityName].venues[venueId]);
          return [...acc, ...venuesInCity];
        }, []);
        newPermissions.venue_ids = venuesIds;
        return {
          ...prevDataUser,
          user_permissions: newPermissions,
        };
      }
      return prevDataUser;
    });

    setChecked(newChecked);
  };

  const handleVenueChange = (cityName: string, venueId: string) => {
    const newChecked = { ...checked };
    const isVenueChecked = !checked[cityName].venues[venueId];
    newChecked[cityName].venues[venueId] = isVenueChecked;

    // Verificar si todos los venues de la ciudad están seleccionados
    const allVenuesChecked = Object.values(newChecked[cityName].venues).every(val => val);
    newChecked[cityName].allChecked = allVenuesChecked;

    // Actualizar el estado de selectedVenueIds
    setSelectedVenueIds((prevSelectedVenueIds) => {
      if (isVenueChecked) {
        return [...prevSelectedVenueIds, venueId];
      } else {
        return prevSelectedVenueIds.filter(id => id !== venueId);
      }
    });

    setDataUser((prevDataUser: any) => {
      if (prevDataUser) {
        const newPermissions = prevDataUser.user_permissions;
        const venuesIds = Object.keys(newChecked).reduce<string[]>((acc, cityName) => {
          const venuesInCity = Object.keys(newChecked[cityName].venues).filter(venueId => newChecked[cityName].venues[venueId]);
          return [...acc, ...venuesInCity];
        }, []);
        newPermissions.venue_ids = venuesIds;
        return {
          ...prevDataUser,
          user_permissions: newPermissions,
        };
      }
      return prevDataUser;
    });

    setChecked(newChecked);
  };

  return (
    <div>
      {cities.map((city) => (
        <div key={city.name}>
          <FormControlLabel
            label={<Typography>{city.name}</Typography>}
            control={
              <Checkbox
                checked={checked[city.name].allChecked}
                indeterminate={
                  Object.values(checked[city.name].venues).some(val => val) &&
                  !checked[city.name].allChecked
                }
                onChange={() => handleCityChange(city.name)}
              />
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {city.venues.map((venue) => (
              <FormControlLabel
                key={venue.id}
                label={venue.name}
                control={
                  <Checkbox
                    checked={checked[city.name].venues[venue.id]}
                    onChange={() => handleVenueChange(city.name, venue.id)}
                  />
                }
              />
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
}
