import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { User, UserPermissionsViewActions } from "../types/users.interface";
import MultipleSelectCheckmarks from "./MultipleSelectCheckmarks";
import { useEffect, useState } from "react";
import { useUpdatePermisssions } from "../hooks/useUpdatePermisssions";
import { useVenues } from "../hooks/useGetVenues";
import useVenuesStore from "../store/venues";
import VenueActivitiesCheckboxes from "./VenueActivitiesCheckboxes";
import { set } from "date-fns";
import { extractPermissions, labelPermissionsGenerator, setAllPermissionToFalse, setAllPermissionToTrue } from "../utils/lib";
import { useCreateUser } from "../hooks/useCreateUser";
import { LoadingCircle } from "../../../customers/components/LoadingCircle";
interface Props {
  user: User | null;
}

const UserForm = ({ user }: Props) => {
  const [dataUser, setDataUser] = useState<User | null>(user);
  const [viewPermissions, setViewPermissions] = useState<Record<string, boolean>>({});
  const [canDoPermissions, setCanDoPermissions] = useState<Record<string, boolean>>({});
  const [selectedVenueIds, setSelectedVenueIds] = useState<string[]>([]);
  const [userVenuesPermissions, setUserVenuesPermissions] = useState<string[]>([]);
  const [labelPermissions, setLabelsPermissions] = useState<Record<string, string>>({});
  const [emailRequired, setEmailRequired] = useState<boolean>(false);
  const [roleRequired, setRoleRequired] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<string | null>(null);

  const { updatePermissions, loading: loadingUpdate } = useUpdatePermisssions();
  const { createUser, loading: loadingCreate } = useCreateUser();

  useEffect(() => {
    if (currentRole) {
      setPermissionsByRole(currentRole);
    }
  }, [currentRole]);

  useEffect(() => {
    const labels = labelPermissionsGenerator(dataUser);
    setLabelsPermissions(labels);
  }, []);

  const { loading, getVenues } = useVenues();
  const { venuesList } = useVenuesStore();

  useEffect(() => {
    if (dataUser) {
      const viewPerms = extractPermissions(dataUser.user_permissions, "can_see_view");
      const canDoPerms = extractPermissions(dataUser.user_permissions, "can_do_in_bookings");
      setViewPermissions(viewPerms);
      setCanDoPermissions(canDoPerms);
      setUserVenuesPermissions(dataUser.user_permissions.venue_ids || []);
    }
    // get venues
    if (venuesList.length === 0) {      
      getVenues();
    }
  }, []);

  useEffect(() => {
    console.log("dataUser", dataUser);
  }, [dataUser]);

  const handlePermissionChange = (permissions: Record<string, boolean>, setPermissions: React.Dispatch<React.SetStateAction<Record<string, boolean>>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataUser) {
      const newPermissions = Object.keys(permissions).reduce((acc, key) => {
        acc[key] = e.target.checked;
        return acc;
      }, {} as Record<string, boolean>);
      setDataUser({
        ...dataUser,
        user_permissions: {
          ...dataUser.user_permissions,
          ...newPermissions,
        },
      });
      setPermissions(newPermissions);
    }
  };

  const handleIndividualPermissionChange = (permissionKey: string, permissions: Record<string, boolean>, setPermissions: React.Dispatch<React.SetStateAction<Record<string, boolean>>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataUser) {
      setDataUser({
        ...dataUser,
        user_permissions: {
          ...dataUser.user_permissions,
          [permissionKey]: e.target.checked,
        },
      });
      setPermissions({
        ...permissions,
        [permissionKey]: e.target.checked,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmailRequired(false);
    if (dataUser) {
      setDataUser({
        ...dataUser,
        [name]: value,
      });

      if (!user?.id) {
        setDataUser({
          ...dataUser,
          [name]: value,
          username: value,
        });
      }
    }
  };

  const handletUpdateUser = () => {
    console.log("Updating user...");
    console.log("data to update", dataUser);
    updatePermissions(dataUser?.id as string, dataUser?.user_permissions);
  };

  const handleSubmit = () => {
    console.log("Submitting user...");
    if (user?.id) {
      handletUpdateUser();
    } else {
      console.log("Creating user...");
      // email and role is required
      if (dataUser?.email && dataUser?.roles && dataUser?.roles.length > 0) {
        console.log("data to create", dataUser);
        createUser(dataUser as User);
      } else {
        console.log("Email and Role is required");
        if (!dataUser?.email) {
          setEmailRequired(true);
        }
        if (!dataUser?.roles || dataUser?.roles.length === 0) {
          setRoleRequired(true);
        }
      }
    }
  };

  const setPermissionsByRole = (role: string) => {
    let newViewPermissions: Record<string, boolean> = {};
    let newCanDoPermissions: Record<string, boolean> = {};
    let newVenuePermissions: string[] = [];

    // before set the permissions, we need to reset the permissions to false
    newViewPermissions = setAllPermissionToFalse(viewPermissions);
    newCanDoPermissions = setAllPermissionToFalse(canDoPermissions);
    newVenuePermissions = [];

    if (role === "ADMIN") {
      newViewPermissions = setAllPermissionToTrue(newViewPermissions);
      newCanDoPermissions = setAllPermissionToTrue(newCanDoPermissions);
      newVenuePermissions = venuesList.map((venue) => venue.id);
    }
    
    if (role === "FINANCE") {
      newViewPermissions = {
        ...newViewPermissions,
        can_see_view_bookings: true,
        can_see_view_overview: true,
        can_see_view_finance: true,
      };

      newCanDoPermissions = {
        ...newCanDoPermissions,
        can_do_in_bookings_export_bookings_report: true,
      };

      newVenuePermissions = venuesList.map((venue) => venue.id);
    }

    if (role === "SUPPORT-1") {
      newViewPermissions = Object.keys(viewPermissions).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as Record<string, boolean>);

      newCanDoPermissions = {
        ...newCanDoPermissions,
        can_do_in_bookings_add_custom_commentaries: true,
        can_do_in_bookings_attach_bills: true,
        can_do_in_bookings_attach_tickets: true,
        can_do_in_bookings_download_bills: true,
        can_do_in_bookings_download_invoice: true,
        can_do_in_bookings_download_tickets: true,
        can_do_in_bookings_edit_booking: true,
        can_do_in_bookings_make_refund: true,
        can_do_in_bookings_manual_purchase: true,
        can_do_in_bookings_mark_contacted_checkbox: true,
        can_do_in_bookings_send_confirmation_mail: true,
        can_do_in_bookings_send_refund_reschedule_mail: true,
        can_do_in_bookings_send_resolution_mail: true,
        can_do_in_bookings_unattach_bills: true,
        can_do_in_bookings_unattach_tickets: true,
        can_do_in_bookings_use_button_resend_bot: true,
      };

      newVenuePermissions = venuesList.map((venue) => venue.id);
    }

    if (role === "SUPPORT-2") {
      newViewPermissions = Object.keys(viewPermissions).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as Record<string, boolean>);

      newCanDoPermissions = {
        ...newCanDoPermissions,
        can_do_in_bookings_make_refund: true,
        can_do_in_bookings_manual_purchase: true,
        can_do_in_bookings_edit_booking: true,
        can_do_in_bookings_add_custom_commentaries: true,
        can_do_in_bookings_attach_bills: true,
        can_do_in_bookings_attach_tickets: true,
        can_do_in_bookings_download_bills: true,
        can_do_in_bookings_download_invoice: true,
        can_do_in_bookings_download_tickets: true,
        can_do_in_bookings_mark_contacted_checkbox: true,
        can_do_in_bookings_send_confirmation_mail: true,
        can_do_in_bookings_send_refund_reschedule_mail: true,
        can_do_in_bookings_send_resolution_mail: true,
        can_do_in_bookings_unattach_bills: true,
        can_do_in_bookings_unattach_tickets: true,
        can_do_in_bookings_use_button_resend_bot: true,
      }

      newVenuePermissions = venuesList.map((venue) => venue.id);
    }

    if (role === "SUPPORT-3") {
      newViewPermissions = setAllPermissionToTrue(newViewPermissions);
      newCanDoPermissions = setAllPermissionToTrue(newCanDoPermissions);
      newVenuePermissions = venuesList.map((venue) => venue.id);
    }

    if (role === "DEV") {
      newViewPermissions = setAllPermissionToTrue(newViewPermissions);
      newCanDoPermissions = setAllPermissionToTrue(newCanDoPermissions);
      newVenuePermissions = venuesList.map((venue) => venue.id);
    }

    if (role === "STAKEHOLDER") {
      newViewPermissions = {
        ...newViewPermissions,
        can_see_view_overview: true,
      };
    }

    // Add more roles and their permissions here if needed

    setViewPermissions(newViewPermissions);
    setCanDoPermissions(newCanDoPermissions);
    setSelectedVenueIds(newVenuePermissions);
    setUserVenuesPermissions(newVenuePermissions);

    if (dataUser) {
      setDataUser({
        ...dataUser,
        user_permissions: {
          ...dataUser.user_permissions,
          ...newViewPermissions,
          ...newCanDoPermissions,
          venue_ids: newVenuePermissions
        },
      });
    }
  };

  return (
    <Grid container marginTop={4}>
      <Grid item xs={12} display={"flex"} gap={2}>
        {user?.id && (
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            name="username"
            value={dataUser?.username}
            sx={{ width: "400px" }}
            onChange={handleInputChange}
            required
          />
        )}
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          name="email"
          value={dataUser?.email}
          sx={{ width: "400px" }}
          onChange={handleInputChange}
          required
          error={emailRequired}
        />
        {/* <MultipleSelectCheckmarks setRoleAdded={setRoleAdded} setRoleRemoved={setRoleRemoved} roles={dataUser?.roles} setRoles={(roles) => {
          if (dataUser) {
            setDataUser({
              ...dataUser,
              roles,
            });
          }
        }} /> */}
        <FormControl>
          <InputLabel id="role">Role</InputLabel>
          <Select id="role" name="role" value={dataUser?.roles} sx={{ width: "300px" }} label="Role" onChange={(e) => {
            if (dataUser) {
              setRoleRequired(false);
              const selectedRole = e.target.value as string;
              setDataUser({
                ...dataUser,
                roles: selectedRole,
              });
              setCurrentRole(selectedRole); // Assuming single role selection
            }
          }}
            required
            disabled={user?.id ? true : false}
            onClick={() => {
              if (user?.id) {
                
              }
            }}
            error={roleRequired}
          >
            <MenuItem value={"ADMIN"}>Admin</MenuItem>
            <MenuItem value={"DEV"}>Dev</MenuItem>
            <MenuItem value={"FINANCE"}>Finance</MenuItem>
            <MenuItem value={"SUPPORT-1"}>Customer Agent Manager</MenuItem>
            <MenuItem value={"SUPPORT-2"}>In-House CSA</MenuItem>
            <MenuItem value={"SUPPORT-3"}>External CSA</MenuItem>
            <MenuItem value={"STAKEHOLDER"}>Stakeholder</MenuItem>
            <MenuItem value={"CS"}>CS</MenuItem>
            <MenuItem value={"PLUGIN"}>Plugin</MenuItem>
            <MenuItem value={"BOT"}>Bot</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} display={"flex"} gap={2} marginTop={4}>
        <FormGroup>
          <h3>Select Views</h3>
          <FormControlLabel
            control={
              <Checkbox
                checked={Object.keys(viewPermissions).every((key) => viewPermissions[key])}
                onChange={handlePermissionChange(viewPermissions, setViewPermissions)}
              />
            }
            label="All"
          />
          {Object.keys(viewPermissions).map((permission) => (
            <FormControlLabel
              key={permission}
              control={
                <Checkbox
                  checked={viewPermissions[permission] ?? false}
                  onChange={handleIndividualPermissionChange(permission, viewPermissions, setViewPermissions)}
                />
              }
              label={labelPermissions[permission]}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item xs={4} display={"flex"} gap={2} marginTop={4}>
        <FormGroup>
          <h3>Select Actions</h3>
          <FormControlLabel
            control={
              <Checkbox
                checked={Object.keys(canDoPermissions).every((key) => canDoPermissions[key])}
                onChange={handlePermissionChange(canDoPermissions, setCanDoPermissions)}
              />
            }
            label="All"
          />
          {Object.keys(canDoPermissions).map((permission) => (
            <FormControlLabel
              key={permission}
              control={
                <Checkbox
                  checked={canDoPermissions[permission] ?? false}
                  onChange={handleIndividualPermissionChange(permission, canDoPermissions, setCanDoPermissions)}
                />
              }
              label={labelPermissions[permission]}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item xs={4} display={"flex"} gap={2} marginTop={4}>
        <FormGroup>
          <h3>Select Venues</h3>
          {
            loading ? (
              <p>Loading venues...</p>
            ) : (
              <VenueActivitiesCheckboxes venues={venuesList} setSelectedVenueIds={setSelectedVenueIds} setDataUser={setDataUser} userVenuesPermissions={userVenuesPermissions} />
            )
          }

        </FormGroup>
      </Grid>
      <Grid item xs={12} display={"flex"} gap={2} marginTop={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}
          disabled={loadingUpdate}
        >
          {user?.id ? "Update" : "Create"}
          { loadingUpdate || loadingCreate ? <LoadingCircle /> : "" }
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserForm;