import { User, UserPermissionsViewActions } from "../types/users.interface";

export const  setAllPermissionToTrue = (permissions: Record<string, boolean>) => {
  return Object.keys(permissions).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as Record<string, boolean>);
};

export const setAllPermissionToFalse = (permissions: Record<string, boolean>) => {
  return Object.keys(permissions).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {} as Record<string, boolean>);
}

export const extractPermissions = (permissions: UserPermissionsViewActions, prefix: string) => {
  return Object.keys(permissions).reduce((acc, key) => {

    if (key === "id") {
      return acc
    }

    if (key === "venue_ids") {
      return acc
    }

    if (key.includes(prefix)) {
      acc[key] = permissions[key as keyof UserPermissionsViewActions] || false;
    }
    return acc;
  }, {} as Record<string, boolean>);
};

export const labelPermissionsGenerator = (dataUser: User | null) => {
  // get the label of the permission by the key
  return Object.keys(dataUser?.user_permissions || {}).reduce((acc, key) => {
    acc[key] = key.replace(/_/g, " ");      
    if (acc[key].includes("can see view")) {        
      acc[key] = key.replace("can_see_view_", "");        
    }
    if (acc[key].includes("can do in bookings")) {        
      acc[key] = key.replace("can_do_in_bookings_", "");        
    }

    acc[key] = acc[key].replace(/_/g, " ");
    // every first letter to uppercase
    acc[key] = acc[key].replace(/\b\w/g, (l) => l.toUpperCase());      
    return acc;
  }, {} as Record<string, string>);
}