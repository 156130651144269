import { useState } from "react";
import { Venue } from "../../../customers/_config/customerService.interface";
import { Config } from "../../../../config";
import useVenuesStore from "../store/venues";

export const useVenues = () => {
  const [loading, setLoading] = useState(false);
  const [venues, setVenues] = useState<Venue[]>([]);
  const API_URL = Config.REACT_APP_API_TICKETDOOR;
  const { setVenuesList } = useVenuesStore();

  const getVenues = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/venues`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("ticketdoorToken")}`,
        },
      });
      const data = await response.json();
      setVenues(data);
      setVenuesList(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  return { venues, getVenues, loading };
}