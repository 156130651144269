import { useState } from "react";
import { User } from "../types/users.interface";
import { Config } from "../../../../config";
import axios from "axios";
import to from "await-to-js";

export const useCreateUser = () => {
  const [loading, setLoading] = useState(false);
  const API_URL = Config.REACT_APP_API_TICKETDOOR;

  const createUser = async (dataUser: User) => {
    try {
      setLoading(true);

      const body = {
        email: dataUser.email,
        roles: [dataUser.roles],
        user_permissions: dataUser.user_permissions,
      };
      console.log(body);
      console.log(API_URL);
      console.log(localStorage.getItem("ticketdoorToken"));
      const [error, response] = await to(
        axios.post(`${API_URL}/users/invitation`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
          },
        })
      );
      if (error) {
        console.error(error);
        return;
      }
      console.log(response);
      const { data } = response;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return { createUser, loading };
};
