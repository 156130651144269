import { Grid } from "@mui/material";
import useUserStore from "./store/users";
import { useEffect, useState } from "react";
import { User } from "./types/users.interface";
import UserForm from "./components/UserForm";
import { useGetUser } from "./hooks/useGetUser";

const UpdateUser = () => {
  const [user, setUser] = useState<User>();
  const { usersList } = useUserStore();
  const { getUser } = useGetUser();

  useEffect(() => {
    if (usersList.length === 0) {      
      const userId = window.location.pathname.split("/").pop();
      if (userId) {
        getUser(userId).then((user) => setUser(user));
      }
    } else {
      const userId = window.location.pathname.split("/").pop();
      const user = usersList.find((user) => user.id === userId);
      setUser(user);
    }
  }, [usersList]);


  return (
    <>
      <Grid padding={"2rem"} marginBottom={"5rem"} container>
        <Grid item xs={12} display={"flex"} gap={"2rem"}>
          <h1>Update User</h1>
        </Grid>
        {user && (
          <UserForm user={user} />
        )}
      </Grid>
    </>
  )
};

export default UpdateUser;