import { Booking, BookingRow, Logs, Notification } from "../_config/customerService.interface";

enum EmailNames {
    emailConfirmation = "email_confirmation",
    emailResolution = "email_resolution",
    emailRefund = "email_refund"
}

function getStatusQontoId(booking: Booking) {
    var statusQontoId = "Error";
    var hasQontoId = booking.booking_handler.purchases_providers[0].payment_provider.payment_id;
    
    if (hasQontoId) {
        statusQontoId = "Success";
    }

    return statusQontoId;
}

export function hasComments(logs?: Logs[]) {
    var result = false;
    //console.log("logs", logs);
    if (logs) {
        for (let i = 0; i < logs.length; i++) {
            if (logs[i].type === "Comment") {
                result = true;
            }
        }
    }

    return result;
}

export function hasBeenContacted(logs?: Logs[]) {
    var result = false;
    if (logs) {
        for (let i = 0; i < logs.length; i++) {
            if (logs[i].type === "Contacted") {
                result = true;
            }
        }
    }

    return result;
}   

export default function createBookingRows(bookings: Booking[]) {
    return bookings.map((booking: Booking) => {
        var OptionVisitDate = booking.cart.items[0].options.find((option) => option.name === "EntryDate");
        var OptionTimeRange = booking.cart.items[0].options.find((option) => option.name === "EntryTime");
        var contact = hasBeenContacted(booking.booking_handler?.purchases_providers[0]?.logs);
        var comments = hasComments(booking.booking_handler?.purchases_providers[0]?.logs);
        var statusEmailConfirmation = getStatusEmail(EmailNames.emailConfirmation, booking.payment?.notifications);
        var statusEmailResolution = getStatusEmail(EmailNames.emailResolution, booking.booking_handler?.purchases_providers[0]?.notifications);
        var statusEmailRefund = getStatusEmail(EmailNames.emailRefund, booking.payment?.notifications);
        var statusNotification = statusEmailConfirmation === "Error" || statusEmailResolution === "Error" || statusEmailRefund === "Error" ? "Error" : "Success";

        return {
            id: booking.id,
            bookingStatus: booking.status,
            createdAt: booking.created_at,
            name: booking.customer.first_name + " " + booking.customer.last_name,
            email: booking.customer.email,
            trackingNumber: booking.booking_handler?.purchases_providers[0]?.tracking_number,
            totalAmount: booking.cart.total_price,
            visitDate: OptionVisitDate?.value,
            timeRange: OptionTimeRange?.value,
            categoryTicket: booking.cart.items[0].activity.name,
            contact,
            errorTag: booking.booking_handler?.purchases_providers[0]?.error_tag,
            statusPurchaseProvider: booking.booking_handler?.purchases_providers[0]?.status,
            statusNotification,
            statusQontoId: getStatusQontoId(booking),
            comments,
            bill: booking.booking_handler?.purchases_providers[0]?.file_management?.has_bill,
            tickets: booking.booking_handler?.purchases_providers[0]?.file_management?.has_tickets
        } as BookingRow;
    });
}

export function getStatusEmail(emailName: string, notifications: Notification[]) {
    var statusEmail = "Error";

    // filter notifications by emailName
    var notificationEmailFilter = notifications.filter((notification) => notification.communication_type === emailName);
    
    var emailError = notificationEmailFilter.find((notification) => notification.communication_type === emailName && notification.status === "Error");
    if (emailError?.status === "Error") return statusEmail;
    
    var emailProcessing = notificationEmailFilter.find((notification) => notification.communication_type === emailName && notification.status === "Processing");
    if (emailProcessing?.status === "Processing") statusEmail = "Processing";

    var emailSuccess = notificationEmailFilter.find((notification) => notification.communication_type === emailName && notification.status === "Success");
    if (emailSuccess?.status === "Success") statusEmail = "Success";

    return statusEmail;
}